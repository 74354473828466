@import "../base";

/** @define News */

.News-date {
  font-size: 1.25rem;
  margin-bottom: 0.25rem;
  display: block;
}

@media (min-width: 700px) {
  .News-date {
    font-size: 2rem;
    margin-bottom: 0.5rem;
    line-height: 1.3;
  }
}

.News-item + .News-item {
  margin-top: 2rem;
}

@media (min-width: 700px) {
  .News-item + .News-item {
    margin-top: 3rem;
  }
}

.News-title {
  font-family: var(--type-font-family-heading);
  font-weight: bold;
  font-size: 2rem;
  line-height: 1.1;
  word-break: break-word;
}

@media (min-width: 700px) {
  .News-title {
    font-size: 3.5rem;
    letter-spacing: -0.005em;
    word-spacing: -0.015em;
  }
}

.News a {
  text-decoration: underline;
  text-decoration-thickness: 0.07em;
  text-underline-offset: 0.05em;
}

.News a:active {
  opacity: 0.5;
}
