@import "../base";

/** @define Callout */

.Callout {
  position: relative;
  z-index: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1.25rem 1rem 1.25rem 1rem;
  font-size: 0.875rem;
}

.Callout--loose {
  padding: 0.75rem 0.75rem 0 0;
}

/* Forgive me, Lord, for I have sinned */

.Slices-item--half:nth-child(2n+2) .Callout--loose,
.Slices-item--space + .Slices-item--half + .Slices-item--half .Callout--loose {
  padding: 0.75rem 0 0 0.75rem;
}

@media (min-width: 700px) {
  .Callout {
    padding: 3.25rem 3rem 3.25rem 3rem;
    font-size: 1.5rem;
  }

  .Callout--loose {
    padding: 3rem 2rem 0 0;
  }

  .Slices-item--half:nth-child(2n+2) .Callout--loose,
  .Slices-item--space + .Slices-item--half + .Slices-item--half .Callout--loose {
    padding: 3rem 0 0 2rem;
  }
}

.Callout::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  background: var(--color-text);
  opacity: 0.2;
}

.Callout--loose::before {
  content: none;
}

.Callout-title {
  font-size: 1.5rem;
  font-family: var(--type-font-family-heading);
  font-weight: bold;
  line-height: 1.1;
  margin-bottom: 0.5rem;
  text-wrap: balance;
}

@media (min-width: 700px) {
  .Callout-title {
    font-size: 3.5rem;
    line-height: 1;
    margin-bottom: 1rem;
    letter-spacing: -0.005em;
    word-spacing: -0.03em;
    max-width: 33rem;
  }

  .Callout--loose .Callout-title {
    margin-bottom: 1.5rem;
  }
}

.Callout-body {
  max-width: 21em;
  text-wrap: balance;
}

@media (min-width: 700px) {
  .Callout-body {
    max-width: 16em;
  }
}

.Callout-body a {
  font-weight: bold;
  text-decoration: underline;
  text-decoration-thickness: 0.07em;
  text-underline-offset: 0.05em;
}

.Callout [data-icon="Linkedin"] {
  margin-left: -0.75rem;
}

.Callout-body a:active {
  opacity: 0.5;
}

.Callout--loose .Callout-body a {
  font-weight: normal;
  text-decoration: none;
}

.Callout-link::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  font-size: 0;
  color: transparent;
  -webkit-touch-callout: none;
}

.Callout-link:active::after {
  opacity: 0.4;
  z-index: 1;
  background-color: var(--color-background);
}

@media (min-width: 700px) {
  .Callout-link::after {
    background-image: url("data:image/svg+xml,%3Csvg width='26' height='20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 10h24' stroke='%23fff' stroke-width='1.75'/%3E%3Cpath d='M16 2l8 8M16 18l8-8' stroke='%23fff' stroke-width='1.75' stroke-linecap='square'/%3E%3C/svg%3E");
    background-position: calc(100% - 3rem) calc(100% - 3rem);
    background-repeat: no-repeat;
    background-size: 3rem;
  }
}

.Callout-link:active::after {
  opacity: 0.4;
}

.Callout-icon {
  display: block;
  width: 2.25rem;
  height: 2.25rem;
  margin: -0.5rem 0 2rem 0;
}

@media (min-width: 700px) {
  .Callout-icon {
    width: 5.75rem;
    height: 5.75rem;
    margin: -1rem 0 2.5rem 0;
  }
}
