@font-face {
  font-family: "GT Pressura";
  font-display: swap;
  font-style: normal;
  font-weight: bold;
  src: url("./fonts/gt-pressura-bold.woff2") format("woff2");
}

@font-face {
  font-family: "GT Pressura";
  font-display: swap;
  font-style: normal;
  font-weight: normal;
  src: url("./fonts/gt-pressura-bold.woff2") format("woff2");
}
