@import "./config";

/** @define utilities */

/**
 * Text
 */

.u-hiddenVisually {
  border: 0 !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
  height: 1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
}

/**
 * Centered page container
 */

.u-container {
  width: 100% !important;
  margin-left: auto !important;
  margin-right: auto !important;
  padding-left: var(--document-margin) !important;
  padding-right: var(--document-margin) !important;
  max-width: calc(var(--container-large-width) + (var(--document-margin) * 2)) !important;
}

@supports (padding-left: constant(safe-area-inset-left)) {
  .u-container {
    padding-left: calc(var(--document-margin) + constant(safe-area-inset-left)) !important;
    padding-right: calc(var(--document-margin) + constant(safe-area-inset-right)) !important;
  }
}

@supports (padding-left: env(safe-area-inset-left)) {
  .u-container {
    padding-left: calc(var(--document-margin) + env(safe-area-inset-left)) !important;
    padding-right: calc(var(--document-margin) + env(safe-area-inset-right)) !important;
  }
}
