@import "../base";

/** @define Illustration */

:root {
  --Illustration-offset: 0;
  --Illustration-offset-a: 0;
  --Illustration-offset-b: 0;
  --Illustration-offset-c: 0;
  --Illustration-neg-offset: calc(var(--Illustration-offset) * -1);
  --Illustration-stroke-width: 1.5;
  /* --Illustration-stroke-width: 0.8; */
}

@media (min-width: 400px) {
  :root {
    --Illustration-stroke-width: 1.2;
  }
}

@media (min-width: 700px) {
  :root {
    --Illustration-stroke-width: 0.95;
  }
}

@media (min-width: 1100px) {
  :root {
    --Illustration-stroke-width: 0.6;
  }
}

.Illustration {
  width: calc(100% + (var(--document-margin) * 2));
  margin-left: calc(var(--document-margin) * -1);
  position: relative;
  z-index: 0;
  pointer-events: none;
  overflow: hidden;
}

@media (min-width: 700px) {
  .Illustration {
    overflow: visible;
  }
}

.Illustration svg {
  overflow: visible;
}

/**
 * Version 1
 */

.Illustration-planet {
  position: relative;
  /* animation: 300s linear Illustration-planet infinite; */
  will-change: transform;
  width: 95%;
  margin-left: 0.52rem;
}

.Illustration-stars {
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: auto;
}

.Illustration-line1,
.Illustration-line2,
.Illustration-line3,
.Illustration-dots {
  width: 100%;
  height: auto;
}

.Illustration-line1,
.Illustration-line2,
.Illustration-line3 {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  will-change: opacity;
  transition: opacity 300ms ease;
}

.Illustration-line1 {
  opacity: var(--Illustration-offset-a);
}

.Illustration-line2 {
  opacity: var(--Illustration-offset-b);
}

.Illustration-line3 {
  opacity: var(--Illustration-offset-c);
}

@keyframes Illustration-planet {
  to {
    transform: rotate(1turn);
  }
}

@media (min-width: 700px) {
  .Illustration-planet {
    width: 46.5%;
    margin: 3rem auto -3.3rem;
  }

  .Illustration-stars {
    display: block;
    top: 56%;
    width: 86%;
  }
}

/**
 * Version 2
 */

.Illustartion-papers {
  width: 35.5rem;
  margin-left: 13rem;
  display: block;
  margin-top: 8.5rem;
  margin-bottom: 2rem;
}

.Illustration-paper:nth-child(5) {
  transform: translateY(calc(-10% * var(--Illustration-offset)));
  transform-origin: center;
}

g.Illustration-paper:nth-child(4) {
  transform: translateY(calc(-12% * var(--Illustration-offset)));
  transform-origin: center;
}

.Illustration-paper:nth-child(3) {
  transform: translateY(calc(-16% * var(--Illustration-offset)));
  transform-origin: center;
}

.Illustration-paper:nth-child(2) {
  transform: translateY(calc(-17% * var(--Illustration-offset)));
  transform-origin: center;
}

.Illustration-paper:nth-child(1) {
  transform: translateY(calc(-8% * var(--Illustration-offset)));
  transform-origin: center;
}

.Illustartion-pencil {
  position: absolute;
  top: -1rem;
  left: 7rem;
  width: 5rem;
}

.Illustartion-goals {
  position: absolute;
  top: 0.7rem;
  right: 8.2rem;
  width: 34.7%;
  transform-origin: 45.75% 47.15%;
}

.Illustartion-goals--animated {
  transform: rotate(calc(-30deg * var(--Illustration-offset)));
}

.Illustartion-arrow {
  position: absolute;
  top: 41rem;
  left: 8rem;
  width: 18.7%;
}

@media (max-width: 699px) {
  .Illustartion-goals {
    position: relative;
    margin: 1.5rem 0 01rem 3.95rem;
    right: 0;
    width: 17rem;
    top: 0;
    transform: rotate(calc(-17deg * var(--Illustration-offset)));
  }

  .Illustartion-arrow,
  .Illustartion-pencil,
  .Illustartion-papers {
    display: none
  }
}

/**
 * Version 3
 */

.Illustration-extra {
  display: none;
}

.Illustration-arrows {
  position: relative;
  width: 75%;
  margin: -4rem auto;
}

.Illustration-lines {
  width: 100%;
  height: auto;
}

.Illustration-1,
.Illustration-2,
.Illustration-3 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  opacity: 0;
  will-change: opacity;
  transition: opacity 300ms ease;
}

.Illustration-1 {
  opacity: var(--Illustration-offset-a);
}

.Illustration-2 {
  opacity: var(--Illustration-offset-b);
}

.Illustration-3 {
  opacity: var(--Illustration-offset-c);
}

@media (max-width: 699px) {
  .Illustration--3 {
    margin-top: -5rem;
    margin-bottom: -6rem;
  }

  .Illustration-arrows {
    position: relative;
    width: 39rem;
    margin: 0 0 0 -7.74rem;
  }
}
