:root {
  /* Document dimentions */
  --document-margin: 0.75rem;
  --document-size: 1.5rem;
  --document-narrow: 1;
  --document-max-width: calc(1440px - (var(--document-margin) * 2));

  /* Color */
  --color-background: #010101;
  --color-text: #ffffff;

  /* Typography */
  --type-font-family: "Helvetica", -apple-system, "BlinkMacSystemFont", "Segoe UI", "Roboto", "Helvetica", "Arial", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  --type-font-family-heading: "GT Pressura", -apple-system, "BlinkMacSystemFont", "Segoe UI", "Roboto", "Helvetica", "Arial", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  --type-line-height: 1.35;

  /* About 16px at a viewport with of 375px */
  --type-font-size: 4.265vw;

  /* Heading */
  --h1-font-size: 2rem;
  --h1-line-height: 1.2;

  /* Container */
  --container-large-width: var(--document-max-width);
  --container-medium-width: 68rem;
  --container-small-width: 30rem;

  /* Focus handling */
  --focus-ring-width: 0.125rem;
  --focus-ring-color: #ff0000;

  /* Easings */
  --ease-in: cubic-bezier(0.4, 0, 1, 1);
  --ease-out: cubic-bezier(0, 0, 0.2, 1);
  --ease: cubic-bezier(0.4, 0, 0.2, 1);
}

@media (min-width: 700px) {
  :root {
    --document-narrow: 0;
    --document-margin: 2rem;

    /* About 16px at a viewport width of 1440px */
    --type-font-size: 1.112vw;
  }
}

@media (min-width: 1440px) {
  :root {
    /* About 16px at a viewport with of 1440px */
    --type-font-size: 16px;
  }
}
