@import "../base";

/** @define View */

.View {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  position: relative;
  z-index: 0;
  background: var(--color-background);
  color: var(--color-text);
}

/**
 * Layouts
 */

.View-main {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}
