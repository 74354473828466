@import "../base";

/** @define Intro */

.Intro h1,
.Intro h2 {
  font-size: 3rem;
  line-height: 0.98;
  font-family: var(--type-font-family-heading);
  font-weight: bold;
  margin-bottom: 0.75rem;
  letter-spacing: -0.005em;
  word-spacing: -0.03em;
  word-break: break-word;
  text-wrap: balance;
}

.Intro-body {
  margin-top: 1.5rem;
  text-wrap: balance;
}

@media (min-width: 700px) {
  .Intro h1,
  .Intro h2 {
    font-size: 6.5rem;
    margin-bottom: 0;
    max-width: 95%;
  }

  .Intro:not(.Intro--large) h2 {
    font-size: 6rem;
    max-width: 52.5rem;
  }

  .Intro--pushed {
    margin-left: 29rem;
  }

  .Intro-body {
    margin-top: 2.5rem;
  }
}

.Intro a {
  font-weight: bold;
  text-decoration: underline;
  text-decoration-thickness: 0.07em;
  text-underline-offset: 0.05em;
  -webkit-touch-callout: none;
}

.Intro a:active {
  opacity: 0.7;
}

.Intro-link {
  display: inline-block;
  margin-top: 0.5rem;
  text-decoration: none !important;
}

.Intro-link {
  background-image: url("data:image/svg+xml,%3Csvg width='26' height='20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 10h24' stroke='%23fff' stroke-width='1.75'/%3E%3Cpath d='M16 2l8 8M16 18l8-8' stroke='%23fff' stroke-width='1.75' stroke-linecap='square'/%3E%3C/svg%3E");
  background-position: center right;
  background-repeat: no-repeat;
  background-size: auto 1.25rem;
  padding: 0 2.4rem 0 0;
}

.Intro-sup {
  display: block;
  margin-bottom: 0.25rem;
  margin-top: -0.25rem;
}

@media (min-width: 700px) {
  .Intro-sup {
    font-size: 2rem;
    margin-bottom: 0.5rem;
  }

  .Intro-link {
    background-size: auto 1.75rem;
    padding: 0 3.5rem 0 0;
  }
}

.Intro-details {
  margin-bottom: 0.75rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 0.75rem;
}

.Intro-details br {
  display: none;
}

.Intro-detail {
  margin-top: 1rem;
}

.Intro-label {
  display: block;
  margin-bottom: 0.25rem;
}

.Intro-tags {
  font-family: var(--type-font-family-heading);
  font-weight: bold;
  line-height: 1.1;
  text-transform: uppercase;
  letter-spacing: 0.02em;
  word-break: break-word;
}

@media (min-width: 700px) {
  .Intro-wrap {
    display: flex;
    align-items: flex-start;
  }

  .Intro-details {
    display: block;
    margin-left: 6rem;
  }

  .Intro-label {
    font-size: 1.25rem;
    margin-bottom: 0.75rem;
  }

  .Intro-tags {
    font-size: 1.5rem;
  }

  .Intro-detail {
    margin-top: 3.7rem;
  }

  .Intro-detail + .Intro-detail {
    margin-top: 2rem;
  }
}
