@import "../base";

/** @define Teasers */

.Teasers-item {
  position: relative;
  display: flex;
  margin-top: 0.75rem;
}

.Teasers-link::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--color-background);
  opacity: 0;
  color: transparent;
  font-size: 0;
}

.Teasers-link:active::after {
  opacity: 0.4;
}

.Teasers-figure {
  flex: 0 0 5rem;
  margin-right: 0.75rem;
}

.Teasers-label {
  margin: 0.2rem 0;
  display: block;
}

.Teasers-title {
  font-family: var(--type-font-family-heading);
  font-weight: bold;
  user-select: text;
  font-size: 1.25rem;
  line-height: 1.1;
}

@media (min-width: 700px) {
  .Teasers {
    margin: 2rem 0;
  }

  .Teasers-list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 2rem;
    grid-row-gap: 4rem;
  }

  .Teaser-item {
    margin: 0;
  }

  .Teasers-figure {
    flex-basis: 12.5rem;
    margin-right: 2rem;
  }

  .Teasers-label {
    margin: 0 0 0.5rem 0;
    font-size: 1.5rem;
  }

  .Teasers-title {
    font-size: 2.5rem;
    line-height: 1.05;
    text-wrap: balance;
    width: 95%;
  }
}
