@import "../base";

/** @define Thoughts */

.Thoughts-item {
  max-width: 52.5rem;
  font-size: 1.125rem;
  line-height: 1.3
}

@media (min-width: 700px) {
  .Thoughts-item {
    font-size: 1.75rem;
    max-width: 60rem;
  }
}

.Thoughts-item + .Thoughts-item {
  margin-top: 1rem;
}

@media (min-width: 700px) {
  .Thoughts-item + .Thoughts-item {
    margin-top: 2rem;
  }
}

.Thoughts-title {
  font-family: var(--type-font-family-heading);
  font-weight: bold;
  font-size: 2rem;
  line-height: 1;
  word-break: break-word;
  text-wrap: balance;
  margin-bottom: 1rem;
}

@media (min-width: 700px) {
  .Thoughts-title {
    font-size: 3.5rem;
    letter-spacing: -0.005em;
    word-spacing: -0.015em;
    margin-bottom: 2rem;
  }
}

.Thoughts a {
  text-decoration: underline;
  text-decoration-thickness: 0.07em;
  text-underline-offset: 0.05em;
}

.Thoughts a:active {
  opacity: 0.5;
}
