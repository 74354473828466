@import "../base";

/** @define Figure */

:root {
  --Figure-aspect: 56.25%;
}

.Figure {
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  text-decoration: none;
  color: currentColor;
  background: transparent;
}

.Figure-container {
  padding-bottom: var(--Figure-aspect);
  position: relative;
  overflow: hidden;
}

.Figure-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  background: var(--color-text);
  opacity: 0.1;
}

@media (max-width: 699px) {
  .Figure--mobile .Figure-container {
    padding-bottom: var(--Figure-aspect-mobile);
  }
}

.Figure-image {
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  object-fit: cover;
  object-position: bottom;
}
