@import "../base";

/** @define Enterence */

.Enterence {
  position: relative;
  user-select: none;
  z-index: 0;
}

@media (min-width: 700px) {
  .Enterence:not(.Enterence--small) .Enterence-meta {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }

  .Enterence:not(.Enterence--small) .Enterence-client {
    margin-top: 1.75rem;
  }
}

.Enterence-link::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--color-background);
  opacity: 0;
  color: transparent;
  font-size: 0;
  -webkit-touch-callout: none;
  z-index: 1;
}

.Enterence-link:active::after {
  opacity: 0.4;
}

.Enterence-label {
  position: absolute;
  top: 0.625rem;
  left: 0.75rem;
  font-size: 0.875rem;
  font-family: var(--type-font-family-heading);
  font-weight: bold;
  line-height: 1.1;
  color: #000000;
  text-transform: uppercase;
  letter-spacing: 0.02em;
}

.Enterence--small .Enterence-label {
  font-size: 0.75rem;
  top: 0.375rem;
  left: 0.5rem;
}

.Enterence-client {
  margin-bottom: 1.1rem;
  margin-top: 0.6rem;
}

@media (min-width: 700px) {
  .Enterence-label,
  .Enterence--small .Enterence-label {
    font-size: 1.5rem;
    top: 1.875rem;
    left: 2rem;
  }

  .Enterence-client {
    margin-bottom: 0;
    margin-top: 0.8rem;
  }
}

.Enterence-label--light {
  color: #ffffff;
}

.Enterence-title {
  font-size: 1.5rem;
  font-family: var(--type-font-family-heading);
  font-weight: bold;
  line-height: 1.1;
  margin: 0.5rem 0 0.5rem;
  user-select: text;
}

.Enterence--small .Enterence-title {
  font-size: 1.25rem;
  line-height: 1;
  margin: 0.625rem 0 0.5rem;
}

@media (min-width: 700px) {
  .Enterence-title {
    font-size: 3rem;
    line-height: 1.1;
    margin: 1.5rem 0 0;
    max-width: 16em;
  }

  .Enterence--small .Enterence-title {
    font-size: 2rem;
    line-height: 1.1;
    margin: 1rem 0 0.625rem;
    max-width: 19.5em;
  }
}
