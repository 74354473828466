@import "../base";

/** @define Words */

.Words {
  max-width: 52.5rem;
  font-size: 1.125rem;
  line-height: 1.3
}

@media (min-width: 700px) {
  .Words {
    font-size: 1.35rem;
  }

  .Words--pushed {
    margin-left: 29rem;
  }

  .Words--large {
    font-size: 1.75rem;
    max-width: 60rem;
  }
}

@media (min-width: 700px) {
  .Words--columns {
    max-width: 100%;
  }

  .Words--columns .Words-main {
    column-count: 2;
    max-width: 100%;
    column-gap: 4rem;
  }

  .Words--columns .Words-header {
    max-width: 52.5rem;
  }

  .Words--columns.Words--large .Words-header {
    max-width: 56rem;
  }

  .Words--columns .Words-main > * {
    break-inside: avoid;
  }
}

/**
 * Links
 */

.Words a {
  color: inherit;
  font-weight: bold;
  text-decoration: underline;
  text-decoration-thickness: 0.07em;
  text-underline-offset: 0.05em;
  word-break: break-word;
}

.Words a:active {
  opacity: 0.5;
}

/**
 * Block elements
 */

.Words p,
.Words ul,
.Words ol,
.Words h2,
.Words h3,
.Words h4 {
  margin: 1rem 0;
}

.Words-header {
  margin-bottom: 1rem;
}

@media (min-width: 700px) {
  .Words-header {
    margin-bottom: 2rem;
  }
}

.Words p:first-child,
.Words ul:first-child,
.Words ol:first-child,
.Words h2:first-child,
.Words h3:first-child,
.Words h4:first-child {
  margin-top: 0;
}

.Words p:last-child,
.Words ul:last-child,
.Words ol:last-child,
.Words h2:last-child,
.Words h3:last-child,
.Words h4:last-child {
  margin-bottom: 0;
}

.Words pre {
  font-size: 12px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  padding: 16px;
  margin: 1rem 0;
  text-align: left;
  border: 1px solid currentColor;
}

/**
 * Lists
 */

.Words ul {
  list-style-type: none;
}

.Words ol {
  list-style-type: decimal;
  padding-left: 1.7em;
}

.Words ul > li {
  padding-left: 1em;
  position: relative;
}

.Words ol > li {
  padding-left: 0.5em;
}

.Words ul > li::before {
  content: "";
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 50%;
  position: absolute;
  left: 0;
  top: 0.5em;
  background: currentColor;
}

/**
 * Headings
 */

.Words h2,
.Words h3,
.Words h4 {
  font-family: var(--type-font-family-heading);
  font-weight: bold;
  line-height: 1.1;
  text-wrap: balance;
}

.Words h2 {
  font-size: 2rem;
  line-height: 1;
  word-break: break-word;
}

.Words h3,
.Words h4 {
  line-height: 1.2;
  font-size: 1.35rem;
}

@media (min-width: 700px) {
  .Words h2 {
    font-size: 3.5rem;
    word-spacing: -0.03em;
    letter-spacing: -0.005em;
  }

  .Words h3,
  .Words h4 {
    font-size: 2.25rem;
    line-height: 1.05;
    max-width: 70%;
  }
}
