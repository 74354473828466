@import "../base";

/** @define Quotes */

.Quotes {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -0.75rem;
  gap: 2rem;
}

.Quotes-item {
  position: relative;
  flex: 1 1 100%;
  padding: 0 0.75rem;
  font-size: 0.8125rem;
}

.Quotes-author {
  margin-top: 0.625rem;
}

.Quotes-client {
  height: 2.5rem;
  display: flex;
  align-items: center;
}

@media (min-width: 700px) {
  .Quotes {
    margin: 0 -0.5rem;
    gap: 0;
  }

  .Quotes-item {
    padding: 0 0.5rem;
    font-size: 1.5rem;
    flex: 1;
  }

  .Quotes-author {
    margin-top: 1.5rem;
    font-size: 1.25rem;
  }

  .Quotes-client {
    height: 7rem;
    margin-bottom: 0;
  }
}
