@import "../base";

/** @define Team */

.Team {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-column-gap: 0.75rem;
  grid-row-gap: 1.5rem;
  margin-bottom: 1rem;
}

@media (min-width: 700px) {
  .Team {
    grid-column-gap: 2rem;
    grid-row-gap: 3rem;
    margin-bottom: 0;
  }

  .Team--alt {
    grid-template-columns: repeat(8, 1fr);
  }
}

.Team-item {
  grid-column: span 3;
}

@media (min-width: 700px) {
  .Team-item {
    grid-column: span 2;
    font-size: 1.5rem;
  }
}

.Team-title {
  font-size: 1.25rem;
  font-family: var(--type-font-family-heading);
  font-weight: bold;
  line-height: 1.1;
  margin-top: 0.75rem;
  margin-bottom: 0.1rem;
}

.Team-body {
  font-size: 0.8125rem;
  margin-top: 0.75rem;
}

.Team-body a {
  font-weight: bold;
}

.Team-body a:active {
  opacity: 0.5;
}

@media (min-width: 700px) {
  .Team-title {
    font-size: 2rem;
    margin: 1.5rem 0 0;
  }

  .Team-body {
    font-size: 1.25rem;
    margin-top: 1rem;
  }
}
