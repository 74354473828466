@import "../base";

/** @define Cases */

.Cases {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -0.75rem;
}

@media (min-width: 700px) {
  .Cases {
    margin: 0 -1.5rem;
  }

  .Cases--pushed {
    margin-left: calc(29rem - 1.5rem);
  }
}

.Cases-title {
  flex-basis: 100%;
  padding: 0 0.75rem;
  margin-top: 1.25rem;
  margin-bottom: 1rem;
  opacity: 0.5;
  font-size: 1rem;
}

@media (min-width: 700px) {
  .Cases-title {
    font-size: 1.25rem;
    margin-top: 0.25rem;
    margin-bottom: 1.25rem;
    padding: 0 1.5rem;
  }
}

.Cases-item {
  position: relative;
  flex: 0 0 50%;
  padding: 0 0.75rem;
  font-size: 1.25rem;
  font-family: var(--type-font-family-heading);
  font-weight: bold;
  text-decoration: underline;
  text-decoration-thickness: 0.07em;
  text-underline-offset: 0.05em;
  line-height: 1.1;
}

@media (min-width: 700px) {
  .Cases-item {
    flex: 0 0 17.5rem;
    padding: 0 1.5rem;
  }
}

.Cases-item:active {
  opacity: 0.4;
}

.Cases-name {
  margin-bottom: 0.625rem;
}

@media (min-width: 700px) {
  .Cases-name {
    margin-bottom: 0.75rem;
    font-size: 1.5rem;
  }
}

.Cases-link::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  font-size: 0;
  opacity: 0;
}
